import { IEnvironment } from "src/app/services/environment.interface";

export const environment: IEnvironment = {
  production: false,
  apiBaseUrl: 'https://as-account-v2-q-0a5579143852.herokuapp.com/',
  defaultAppUrl: 'https://qa-charts.healthexam.com/app-redirect',
  remitAppUrl: 'https://as-account-v2-q-0a5579143852.herokuapp.com/',
  adminAppUrl: 'https://qa-admin.healthexam.com',
  phiAppUrl: 'https://qa-patients.healthexam.com',
  studiesAppUrl: 'https://qa-studies.healthexam.com',
  billerAppUrl: 'https://qa-biller.healthexam.com',
  chartsAppUrl: 'https://qa-charts.healthexam.com',
  clinicAppUrl: 'https://qa-clinic.healthexam.com',
  signatureKey: 'heathexamplatform@lo!@0oa8zj*4j8y=tx*6t$ytx-4=nqne8pbi8_tiv9l(8jaf('
}
